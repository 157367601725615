'use client';

import type { UseEmblaCarouselType } from 'embla-carousel-react';
import useEmblaCarousel from 'embla-carousel-react';
import * as React from 'react';

import { Button } from '@zealy/design-system';
import { ArrowLeftLine, ArrowRightLine } from '@zealy/icons';

import { cn } from '#utils/utils';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  setApi?: (api: CarouselApi) => void;
  onFetchMore?: () => void;
  step?: number;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const Carousel = ({
  orientation = 'horizontal',
  opts,
  setApi,
  plugins,
  className,
  children,
  onFetchMore,
  step = 1,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & CarouselProps) => {
  const onFetchMoreRef = React.useRef(onFetchMore);
  const [carouselRef, api] = useEmblaCarousel(
    {
      ...opts,
      dragFree: true,
      slidesToScroll: step,
      axis: orientation === 'horizontal' ? 'x' : 'y',
    },
    plugins,
  );
  const [canScrollPrev, setCanScrollPrev] = React.useState(false);
  const [canScrollNext, setCanScrollNext] = React.useState(false);

  onFetchMoreRef.current = onFetchMore;

  const scrollPrev = React.useCallback(() => {
    api?.scrollPrev();
  }, [api]);

  const scrollNext = React.useCallback(() => {
    api?.scrollNext();
  }, [api]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        scrollPrev();
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        scrollNext();
      }
    },
    [scrollPrev, scrollNext],
  );

  React.useEffect(() => {
    if (!api || !setApi) {
      return;
    }

    setApi(api);
  }, [api, setApi]);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    const onSelect = (api: CarouselApi) => {
      if (!api) {
        return;
      }

      const indexToFetchNext = api.scrollSnapList().length - 2;
      const selectedIndex = api.selectedScrollSnap();

      if (indexToFetchNext === selectedIndex && onFetchMoreRef.current) {
        onFetchMoreRef.current();
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    };

    onSelect(api);
    api.on('reInit', onSelect);
    api.on('select', onSelect);

    return () => {
      api?.off('select', onSelect);
    };
  }, [api]);

  return (
    <CarouselContext.Provider
      value={{
        carouselRef,
        api: api,
        opts,
        orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
        scrollPrev,
        scrollNext,
        canScrollPrev,
        canScrollNext,
        step,
      }}
    >
      <div
        onKeyDownCapture={handleKeyDown}
        className="relative"
        role="region"
        aria-roledescription="carousel"
        {...props}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  );
};

const CarouselContent = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div
        className={cn(
          'flex gap-200 md:gap-300 w-full py-300 px-100',
          orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
          className,
        )}
        {...props}
      />
    </div>
  );
};

const CarouselItem = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      role="group"
      aria-roledescription="slide"
      className={cn('', 'pl-4', className)}
      {...props}
    />
  );
};

const CarouselPrevious = () => {
  const { scrollPrev, canScrollPrev } = useCarousel();

  return (
    <Button
      type="button"
      variant="muted"
      leftIcon={<ArrowLeftLine />}
      onlyIcon
      onClick={scrollPrev}
      isDisabled={!canScrollPrev}
      aria-label="Previous page"
    />
  );
};

const CarouselNext = () => {
  const { scrollNext, canScrollNext } = useCarousel();

  return (
    <Button
      type="button"
      variant="muted"
      leftIcon={<ArrowRightLine />}
      onlyIcon
      onClick={scrollNext}
      isDisabled={!canScrollNext}
      aria-label="Next page"
    />
  );
};

const components = {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
};

export default components;
