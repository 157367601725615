import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

import { Avatar, Button } from '@zealy/design-system';
import { CrossLine, GearLine, LogOut2Line, MenuLine } from '@zealy/icons';
import { useAuthenticatedUser } from '@zealy/queries';

import { CREATE_COMMUNITY_PATH } from '#constants/routes';

import { NAVBAR_LINKS } from './Navbar.const';
import { useNavbarActions } from './Navbar.helpers';

export const MobileNavMenu = () => {
  const user = useAuthenticatedUser();
  const [open, setOpen] = useState(false);

  const { signOut, openSettings } = useNavbarActions();
  const t = useTranslations('navbar');

  return (
    <Dialog.Root open={open} onOpenChange={newValue => setOpen(newValue)}>
      <Dialog.Trigger asChild>
        <div>
          <Button
            aria-label="Menu"
            onlyIcon
            leftIcon={<MenuLine />}
            className="md:hidden bg-button-neutral"
          />
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-primary z-50 fixed inset-0" />
        <Dialog.Content className="flex flex-col bg-tertiary z-50 rounded-component-xl fixed top-150 left-[50%] h-[85vh] w-[95vw] max-w-[500px] translate-x-[-50%] p-component-lg p-t-component-md shadow-lg focus:outline-none">
          <Dialog.Close asChild className="self-end mb-2">
            <span>
              <Button aria-label="Close" leftIcon={<CrossLine />} variant="muted" onlyIcon />
            </span>
          </Dialog.Close>
          <div className="m-0 p-100 gap-100 list-none flex flex-col flex-1 overflow-y-scroll">
            <Link
              key="register"
              href={CREATE_COMMUNITY_PATH}
              className="py-200 hover:bg-component-secondary-hover px-200 rounded-component-sm transition-colors duration-30 title-2 font-bold cursor-pointer focus:outline-none focus:ring-link-focus"
            >
              {t('create-community')}
            </Link>
            {/* {NAVBAR_LINKS.product.items.map((item) => (
                <NextLink
                  key={item.path}
                  href={item.path}
                  className='py-150 hover:bg-component-secondary-hover px-200 rounded-component-sm transition-colors duration-30 title-2 font-bold cursor-pointer focus:outline-none focus:ring-link-focus'
                >
                  {t(`${item.label}.title`)}
                </NextLink>
              ))} */}
            {NAVBAR_LINKS.resources.items.map(item => (
              <Link
                prefetch={false}
                key={item.path}
                href={item.path}
                className="py-200 hover:bg-component-secondary-hover px-200 rounded-component-sm transition-colors duration-30 title-2 font-bold cursor-pointer focus:outline-none focus:ring-link-focus"
              >
                {t(`${item.label}.title`)}
              </Link>
            ))}
            {NAVBAR_LINKS.company.items.map(item => (
              <Link
                key={item.path}
                href={item.path}
                className="py-200 hover:bg-component-secondary-hover px-200 rounded-component-sm transition-colors duration-30 title-2 font-bold cursor-pointer focus:outline-none focus:ring-link-focus"
                onClick={() => setOpen(false)}
              >
                {t(`${item.label}.title`)}
              </Link>
            ))}
          </div>
          {user.data ? (
            <div className="border-t-separators pt-200">
              <div className="flex gap-150 items-center">
                <Avatar src={user.data?.avatar} name={user.data?.name} as={Image} />
                <p className="component-lg">{user.data?.name}</p>
              </div>
              <div className="flex gap-100 mt-200">
                <Button
                  onClick={() => {
                    setOpen(false);
                    openSettings();
                  }}
                  variant="muted"
                  className="flex-1"
                  leftIcon={<GearLine />}
                >
                  {t('account-settings')}
                </Button>
                <Button onClick={signOut} variant="muted" leftIcon={<LogOut2Line />}>
                  {t('logout')}
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex gap-100 border-t-separators pt-200">
              <Button as={Link} href="/login" variant="muted" className="flex-1">
                {t('login')}
              </Button>
              <Button as={Link} href="/signup" className="flex-1">
                {t('sign-up')}
              </Button>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
