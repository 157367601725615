'use client';

import { Switch } from '@zealy/design-system';

import { useCookiePreferences } from '#context';
import { useDidMount } from '#hooks/useDidMount';

interface CookieOptionProps {
  option: 'essential' | 'functional' | 'analytics';
  label: string;
  description: string;
  disabled?: boolean;
  checked?: boolean;
  onClick?: () => void;
}

export const CookieOption = ({ option, label, description }: CookieOptionProps) => {
  const { preferences, setPreferences } = useCookiePreferences();

  const mounted = useDidMount();

  if (!mounted) {
    return null;
  }

  const disabled = option === 'essential';

  return (
    <div
      className="flex items-center gap-300 w-full mb-400"
      key={disabled || !!preferences?.[option] ? 't' : 'f'}
    >
      <Switch
        id={option}
        disabled={disabled}
        checked={disabled || !!preferences?.[option]}
        onCheckedChange={(checked: boolean) => {
          setPreferences?.({
            [option]: checked,
          });
        }}
      />
      <label htmlFor={option} id={option} className="body-md body-bold text-primary">
        {label}
        <p className="body-md text-secondary">{description}</p>
      </label>
    </div>
  );
};
