'use client';

//@ts-ignore
import React from 'react';
import { useCookie } from 'react-use';
import { LazyMotion, m, useScroll, useTransform } from 'framer-motion';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { SearchInput } from '#context/Search';

import images from './assets';

// Make sure to return the specific export containing the feature bundle.
//@ts-ignore
const loadFeatures = () => import('./features').then(res => res.default);

export const HeroElement = () => {
  const [subdomain, setSubdomain] = useCookie('subdomain');

  const t = useTranslations('hero');
  const { scrollY } = useScroll();
  const illustrationY = useTransform(scrollY, [0, 600], [0, 120]);
  const illustrationOpacity = useTransform(scrollY, [0, 550], [1, 0]);
  const filter = useTransform(scrollY, (v: number) => (v > 200 ? `blur(${v / 150}px)` : ''));
  const starsBackgroundPosition = useTransform(scrollY, (v: number) => {
    if (v > 600) return '0 150px';
    return `0 ${v / 4}px`;
  });

  if (subdomain !== 'root' && typeof window !== 'undefined') {
    setSubdomain('root', { domain: `.${window.location.hostname}` });
  }

  return (
    <div className="bg-primary overflow-visible sticky mt-[-160px] top-[-613px] md:top-[-620px] w-full z-20 drop-shadow-[0px_8px_8px_var(--color-bg-primary)] px-100">
      <div className="relative pt-400 rounded-b-component-md border-component-md border-component-tertiary border-t-0 overflow-hidden">
        <m.div
          style={{
            backgroundPosition: starsBackgroundPosition,
          }}
          className="flex flex-col-reverse md:flex-row relative h-[597px] md:h-[601px] pb-300 rounded-b-component-md mt-1000 overflow-hidden bg-primary bg-stars bg-blend-screen bg-contain"
        >
          <m.div
            className="pt-200 md:pt-0 md:ml-1200 md:max-w-[500px] md:justify-center flex flex-col h-full flex-2 px-300 md:pr-0 z-[1]"
            style={{
              opacity: illustrationOpacity,
            }}
          >
            <h1 className="display-2 uppercase text-primary mb-100">{t('title')}</h1>
            <h2 className="body-xl text-secondary mb-300">{t('description')}</h2>
            <SearchInput isLanding />
          </m.div>

          <m.picture
            className="hidden dark:block object-center object-contain w-full h-full flex-3 mix-blend-lighten mr-100 saturate-[1.1] max-h-[350px] md:max-h-full z-[1] relative top-[16px]"
            style={{
              opacity: illustrationOpacity,
              y: illustrationY,
              filter,
            }}
          >
            <source srcSet={images.portal.lg.src} media="(min-width: 1200px)" />
            <source srcSet={images.portal.md.src} media="(min-width: 800px)" />
            <Image
              src={images.portal.sm}
              alt=""
              width={500}
              height={500}
              className="block object-left object-contain w-full h-full flex-3 select-none pointer-events-none "
              priority
            />
          </m.picture>
        </m.div>

        <m.div
          className="absolute top-0 left-0 right-0 bottom-0"
          style={{
            opacity: illustrationOpacity,
            y: illustrationY,
          }}
        >
          <Image
            src={images.top}
            alt=""
            width={120}
            height={56}
            priority
            className="object-center object-contain absolute translate-y-500 top-[-10px] md:top-0 left-0 right-0 mx-auto select-none pointer-events-none saturate-150 scale-x-[-1]"
          />

          <Image
            src={images.bottom}
            alt=""
            width={230}
            height={30}
            priority
            className="hidden md:block object-cover absolute translate-y-[9px] bottom-0 rotate-6 left-0 select-none pointer-events-none saturate-150"
          />

          <Image
            src={images.left}
            alt=""
            width={200}
            height={20}
            priority
            className="hidden md:block object-left object-contain absolute left-0 top-0 bottom-0 my-auto w-[128px] h-1800 select-none pointer-events-none saturate-150"
          />

          <Image
            src={images.right}
            alt=""
            width={50}
            height={100}
            priority
            className="object-right object-contain absolute right-0 top-[80px] bottom-0 my-auto w-[96px] h-1800 select-none pointer-events-none saturate-150"
          />
        </m.div>
      </div>
    </div>
  );
};

export const Hero = () => {
  return (
    <LazyMotion features={loadFeatures}>
      <HeroElement />
    </LazyMotion>
  );
};
