import bottomWebp from './bottom.webp';
import leftWebp from './left.webp';
import portalLgWebp from './portal-lg.webp';
import portalMdWebp from './portal-md.webp';
import portalSmWebp from './portal-sm.webp';
import rightWebp from './right.webp';
import topWebp from './top.webp';

const images = {
  top: topWebp,
  right: rightWebp,
  bottom: bottomWebp,
  left: leftWebp,
  portal: {
    sm: portalSmWebp,
    md: portalMdWebp,
    lg: portalLgWebp,
  },
};

export default images;
