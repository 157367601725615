import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { ComponentProps, forwardRef, ReactNode } from 'react';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { Button } from '@zealy/design-system';
import { ChevronDownLine } from '@zealy/icons';

import { NAVBAR_LINKS, NavbarLinkMapper } from './Navbar.const';

interface NavMenuItemProps {
  title: string;
  children: ReactNode;
}

const NavMenuItem = forwardRef<HTMLAnchorElement, NavMenuItemProps & ComponentProps<'a'>>(
  ({ children, title, href, ...props }, forwardedRef) => {
    return (
      <li>
        <NavigationMenu.Link asChild>
          <Link
            prefetch={false}
            className="focus:ring-button-focus hover:bg-component-secondary-hover block select-none rounded-component-sm p-200 text-[15px] leading-none no-underline outline-none transition-colors"
            href={href as string}
            {...props}
            ref={forwardedRef}
          >
            <div className="text-primary mb-[5px] font-medium leading-[1.2]">{title}</div>
            <p className="text-secondary component-md leading-[1.4]">{children}</p>
          </Link>
        </NavigationMenu.Link>
      </li>
    );
  },
);

NavMenuItem.displayName = 'NavMenuItem';

export const NavMenu = ({ title }: { title: keyof NavbarLinkMapper }) => {
  const section = NAVBAR_LINKS[title];
  const t = useTranslations('navbar');

  return (
    <NavigationMenu.Item>
      <NavigationMenu.Trigger asChild>
        <div>
          <Button variant="ghost" rightIcon={<ChevronDownLine />}>
            {t(section.title)}
          </Button>
        </div>
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto overflow-visible">
        <ul
          className={cx(
            'one m-0 grid list-none gap-x-[10px] p-component-xs sm:w-[250px] grid-rows-3',
            section.items.length > 3 ? 'grid-cols-[150px_1fr] sm:w-[400px]' : 'grid-cols-1',
          )}
        >
          {section.items.map(item =>
            (item as { type?: string }).type === 'image' ? (
              <NavigationMenu.Link asChild className="row-span-3" key={item.path}>
                <a
                  className="dark focus:shadow-sm bg-[#633274] bg-discord-banner bg-center bg-cover flex hover:ring-2 focus:ring-2 ring-inset ring-[color:#f0f1e73b] transition-color duration-300 hover:shadow-sm
              h-full w-full select-none bg-blend-overlay flex-col overflow-hidden rounded-component-sm p-component-sm no-underline outline-none"
                  href={item.path}
                >
                  <div className="mt-100 mb-[7px] text-[18px] font-medium leading-[1.2] text-primary">
                    {t(`${item.label}.title`)}
                  </div>
                  <p className="text-[14px] text-secondary leading-[1.3]">
                    {t(`${item.label}.description`)}
                  </p>
                </a>
              </NavigationMenu.Link>
            ) : (
              <NavMenuItem href={item.path} title={t(`${item.label}.title`)} key={item.path}>
                {t(`${item.label}.description`)}
              </NavMenuItem>
            ),
          )}
        </ul>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
};
