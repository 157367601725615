import Link from 'next/link';

import { Logo } from './Logo';

export const LogoFull = ({
  href = '/',
  className = '',
  width = 120,
  height,
}: {
  href?: string;
  className?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <Link href={href}>
      <svg
        role="img"
        aria-label="Logo"
        width={width}
        height={height}
        viewBox="0 0 119 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g clipPath="url(#clip0_2976_43861)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.50019 10.5786L8.61686 10.5318L8.61286 11.4239L8.61241 11.6099C8.61215 11.7547 8.6118 12.0048 8.61145 12.4261C8.61074 13.2687 8.61004 14.7967 8.61004 17.5387V17.8909L9.65667 18.9465C9.24225 19.2095 8.82588 19.4694 8.40759 19.7263L8.00328 19.9746L8 25.8553L12.8276 30.7273L13.1446 30.7481C19.0682 31.1384 25.1604 30.7054 30.8683 28.7194L31.4365 28.5217V21.4552L30.2016 20.2098C30.412 20.0003 30.6219 19.7891 30.8303 19.5765L31.0739 19.328V18.9786C31.0739 18.7374 31.0746 17.4744 31.0753 16.2711L31.0769 13.5729L26.1045 8.55801L25.6096 8.72523C20.4705 10.4615 14.9587 10.8676 9.50019 10.5786ZM25.8774 9.53151L30.2336 13.9249C30.2336 13.9249 30.2308 18.4956 30.2308 18.9786C30.0237 19.1898 29.8149 19.4 29.6054 19.6085C29.4048 19.8082 29.2035 20.0065 29.0024 20.2029L30.5933 21.8074V27.9155C25.0227 29.8538 19.05 30.2851 13.1995 29.8996L8.84333 25.5034L8.84614 20.4525C9.32251 20.16 9.79648 19.8635 10.268 19.563C10.5113 19.4079 10.7539 19.2518 10.9959 19.0946L9.45317 17.5387C9.45317 14.6283 9.45397 13.0859 9.45471 12.2792C9.45537 11.5659 9.45599 11.4277 9.45599 11.4277C9.73675 11.4426 10.0178 11.4557 10.299 11.4668C15.5513 11.6753 20.8739 11.222 25.8774 9.53151Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.8773 9.53149C20.606 11.3125 14.9803 11.7203 9.45592 11.4277C9.45592 11.4277 9.45311 12.055 9.45311 17.5387L10.9958 19.0946C10.285 19.5564 9.56828 20.009 8.84607 20.4525L8.84326 25.5034L13.1994 29.8996C19.05 30.2851 25.0226 29.8538 30.5933 27.9155V21.8074L29.0023 20.2029C29.4133 19.8014 29.8253 19.3921 30.2307 18.9786C30.2307 18.4956 30.2335 13.9248 30.2335 13.9248L25.8773 9.53149ZM28.5444 18.596V16.1471L28.5416 16.1442C24.7475 17.2128 20.9534 17.6181 17.1593 17.6181C16.6029 17.6181 16.0492 17.6096 15.4927 17.5926V20.3023C16.0548 20.3193 16.6141 20.3278 17.1762 20.3278C19.1294 20.3278 21.0827 20.2201 23.036 19.9678C20.3211 22.1702 17.6034 24.0947 14.8857 25.8379V28.2868C15.6445 28.318 16.4033 28.335 17.1621 28.335C21.0771 28.335 24.992 27.9014 28.9098 26.7563V24.0466C26.0684 24.8742 23.2327 25.3305 20.3942 25.5205C23.109 23.4882 25.8267 21.2093 28.5444 18.596Z"
            fill="#0D0D0D"
          />
          <path
            d="M28.5445 16.1471V18.596C25.8268 21.2093 23.1091 23.4882 20.3942 25.5205C23.2327 25.3306 26.0685 24.8742 28.9098 24.0466C28.9098 24.9508 28.9098 25.8521 28.9098 26.7563C24.9921 27.9014 21.0771 28.3351 17.1622 28.3351C16.4034 28.3351 15.6446 28.318 14.8857 28.2869V25.8379C17.6034 24.0948 20.3211 22.1702 23.036 19.9679C21.0828 20.2201 19.1295 20.3278 17.1762 20.3278C16.6141 20.3278 16.0549 20.3193 15.4928 20.3023C15.4928 19.3981 15.4928 18.4968 15.4928 17.5926C16.0492 17.6096 16.6029 17.6181 17.1594 17.6181C20.9535 17.6181 24.7476 17.2128 28.5417 16.1442L28.5445 16.1471Z"
            fill="white"
          />
        </g>
        <path
          d="M55.2803 25.1615V22.0188H47.1669L54.7463 17.2739V14.275H41.4567V17.4177H48.502L40.9226 22.1626V25.1615H55.2803Z"
          fill="currentColor"
        />
        <path
          d="M71.3291 21.649H66.687C66.2762 22.2653 65.7421 22.4707 63.7086 22.4707C61.4697 22.4707 60.6892 22.2447 60.6276 20.4577H71.4113V19.3485C71.4113 15.2815 68.9875 14.0696 63.7908 14.0696H63.4622C58.2654 14.0696 56.006 15.2815 56.006 19.3485V20.088C56.006 24.155 58.2654 25.3669 63.4622 25.3669H63.8319C69.1108 25.3669 70.7335 24.3398 71.3291 21.649ZM63.7086 16.6988C65.8654 16.6988 66.7897 17.0275 66.7897 18.342V18.4242H60.6481C60.7919 16.9248 61.6546 16.6988 63.7086 16.6988Z"
          fill="currentColor"
        />
        <path
          d="M87.5501 25.1615V18.7118C87.5501 15.1583 86.0507 14.0696 80.7102 14.0696H80.3199C75.1848 14.0696 73.6032 15.3226 72.8432 17.6026H77.6496C77.9783 16.9864 78.5123 16.7193 80.2377 16.7193C82.2712 16.7193 82.9285 17.0275 82.9285 18.2804V18.4447L76.2324 18.6707C73.8497 18.7528 72.3708 19.5539 72.3708 21.6901V21.8544C72.3708 24.1755 73.9318 25.3669 77.6907 25.3669C80.7307 25.3669 82.2507 24.6069 82.8053 23.2307H82.9285V25.1615H87.5501ZM77.0334 21.7723V21.6901C77.0334 21.0534 77.4853 20.7863 78.615 20.7453L82.9285 20.6015V20.7453C82.9285 22.1009 81.5523 22.8815 79.2929 22.8815C77.6496 22.8815 77.0334 22.5734 77.0334 21.7723Z"
          fill="currentColor"
        />
        <path d="M93.5076 25.1615V10.7832H88.886V25.1615H93.5076Z" fill="currentColor" />
        <path
          d="M102.619 28.6533L110.116 14.275H105.392L102.434 20.7453H102.29L99.209 14.275H94.2383L99.8047 25.2231L97.7712 28.6533H102.619Z"
          fill="currentColor"
        />
        <defs>
          <clipPath id="clip0_2976_43861">
            <rect width="23.4365" height="23.4365" fill="white" transform="translate(8 8)" />
          </clipPath>
        </defs>
      </svg>
    </Link>
  );
};

export { Logo };
