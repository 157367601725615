'use client';

import type { ReactNode } from 'react';
import { useFormatter } from 'next-intl';
import NextLink from 'next/link';

import { cn, getValidChildren, Link } from '@zealy/design-system';

import RawCarousel from './RawCarousel';

interface CarouselProps {
  /**
   * Elements to be displayed in the carousel
   */
  children: ReactNode;
  /**
   * Callback when reaching the end of the carousel if items.length < totalItems
   */
  onFetchMore?: () => void;
  /**
   * Total items available
   */
  totalItems?: number;
  /**
   * Title displayed above the carousel
   */
  title: string;
  /**
   * Additional class names to be added
   */
  className?: string;
  /**
   * Label for the show all link
   */
  showAllLabel?: string;
  /**
   * Link for the show all link
   */
  showAllLink?: string;

  /**
   * Navigation step (how many entry should be skipped when pressing next/prev)
   */
  step?: number;
}

export const Carousel = ({
  children,
  totalItems = 0,
  onFetchMore,
  title,
  className,
  showAllLink,
  showAllLabel = 'Show all',
  step = 1,
}: CarouselProps) => {
  const childrenArray = getValidChildren(children);
  const format = useFormatter();

  return (
    <div className={cn('flex flex-col relative', className)}>
      <RawCarousel.Carousel onFetchMore={onFetchMore} step={step}>
        <div className="flex justify-between items-center gap-200 min-w-0 w-full px-100">
          <h2 className="title-2 font-bold mr-auto">{title}</h2>
          {!!totalItems && totalItems > childrenArray.length && showAllLink && (
            <Link as={NextLink} prefetch={false} underlined href={showAllLink}>
              {showAllLabel} ({format.number(totalItems, { notation: 'compact' })})
            </Link>
          )}

          <div className="gap-100 hidden md:flex">
            <RawCarousel.CarouselPrevious />
            <RawCarousel.CarouselNext />
          </div>
        </div>

        <RawCarousel.CarouselContent>
          {childrenArray.map((child, index: number) => (
            <RawCarousel.CarouselItem key={`carousel-item-${index}`}>
              {child}
            </RawCarousel.CarouselItem>
          ))}
        </RawCarousel.CarouselContent>
      </RawCarousel.Carousel>
    </div>
  );
};
