'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useMeasure, useScroll } from 'react-use';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';

import { Button, Select, SelectItem, Tag } from '@zealy/design-system';
import { ArrowLeftLine, ArrowRightLine } from '@zealy/icons';

import type { ExploreType } from '#constants';
import { COMMUNITY_CATEGORIES, COMMUNITY_CATEGORY_IDS, COMMUNITY_COLLECTIONS } from '#constants';

export const Filters = ({
  selected,
  className,
  web2,
}: {
  selected?: string;
  className?: string;
  web2?: boolean;
}) => {
  const t = useTranslations('filters');
  const [maxScrollX, setMaxScrollX] = useState<number>();
  const [containerRef, width] = useMeasure<HTMLDivElement>();
  const router = useRouter();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { x } = useScroll(scrollRef);

  const isSelected = useCallback(
    (value?: ExploreType) => {
      return value === selected;
    },
    [selected],
  );

  const scrollRight = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  }, [scrollRef]);

  const scrollLeft = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  }, [scrollRef]);

  useEffect(() => {
    if (scrollRef.current) {
      setMaxScrollX(scrollRef.current.scrollWidth - scrollRef.current.clientWidth);
    }
  }, [width]);

  const goToExplorePage = useCallback(
    (value: string) => {
      const categoryIndex = Object.values(COMMUNITY_CATEGORY_IDS).findIndex(item => item === value);
      const categoryId = Object.keys(COMMUNITY_CATEGORY_IDS).at(categoryIndex);
      if (categoryId) {
        router.push(`/explore/${categoryId}`);
      }
    },
    [router],
  );

  return (
    <div
      className={cx(
        'sticky top-[92px] z-10 flex flex-col w-full items-center bg-fading from-primary pb-400 pt-100 gap-100',
        className,
      )}
    >
      <div className="flex flex-row pt-100 items-center gap-300 overflow-x-scroll no-scrollbar w-full min-w-0">
        {/* {!pathname.includes('explore') && <Interests />}
        {!web2 && !pathname.includes('explore') && (
          <div className="w-[1px] flex shrink-0 h-400 bg-white/20" />
        )} */}
        {!web2 && (
          <>
            <div className="flex flex-row gap-100 min-w-0 items-center">
              {COMMUNITY_COLLECTIONS.map(collection => (
                <Tag
                  className={cx(collection.value === 'trend' ? 'flex' : 'hidden md:flex')}
                  key={collection.value}
                  selected={isSelected(collection.value)}
                  onClick={() => router.push(`/explore/${collection.seo}`)}
                  leftIcon={collection.icon}
                >
                  {t(`categories.${collection.value}`)}
                </Tag>
              ))}

              <Select
                placeholder={t('labels.collections')}
                name="collections"
                className="md:hidden"
                onValueChange={goToExplorePage}
              >
                {COMMUNITY_COLLECTIONS.map(collection => {
                  return (
                    <SelectItem
                      key={collection.value}
                      value={collection.value}
                      leftIcon={collection.icon}
                    >
                      {t(`categories.${collection.value}`)}
                    </SelectItem>
                  );
                })}
              </Select>
            </div>

            <div className="w-[1px] flex shrink-0 h-400 bg-white/20" />
            <div className="flex flex-1 flex-row gap-100 md:overflow-hidden  min-w-0">
              <div
                ref={containerRef}
                className="flex flex-1 flex-row items-center md:overflow-x-hidden"
              >
                <div className="flex flex-row relative md:overflow-x-scroll md:no-scrollbar">
                  {x > 0 && (
                    <div className="hidden md:flex absolute left-0 top-0 bottom-0 items-center">
                      <Button
                        aria-label="Scroll left"
                        onClick={scrollLeft}
                        className="hidden md:flex z-[1]"
                        variant="muted"
                        onlyIcon
                        leftIcon={<ArrowLeftLine />}
                      />
                      <div className="hidden md:flex absolute pointer-events-none left-0 w-[150px] h-full bg-gradient-to-r from-[var(--color-bg-primary)] to-transparent" />
                    </div>
                  )}
                  <div
                    ref={scrollRef}
                    data-testid="filters-scroll"
                    className="flex flex-1 flex-row gap-100 md:overflow-x-scroll md:no-scrollbar md:snap-x"
                  >
                    {COMMUNITY_CATEGORIES.map(category => (
                      <Tag
                        selected={isSelected(category.value)}
                        key={category.value}
                        onClick={() => router.push(`/explore/${category.seo}`)}
                        className="snap-start my-1"
                      >
                        {t(`categories.${category.value}`)}
                      </Tag>
                    ))}
                  </div>
                  {!!maxScrollX && x < maxScrollX && (
                    <div className="hidden md:flex absolute right-0 top-0 bottom-0 items-center">
                      <Button
                        onClick={scrollRight}
                        aria-label="Scroll right"
                        className="hidden md:flex z-[1]"
                        variant="muted"
                        onlyIcon
                        leftIcon={<ArrowRightLine />}
                      />
                      <div className="hidden md:flex absolute pointer-events-none right-0 w-[150px] h-full bg-gradient-to-r from-transparent to-[var(--color-bg-primary)]" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
