import { COMMUNITY_URL, DOCUMENTATION_URL } from '#constants';

import solutionsImage from './assets/black.webp';

export const NAVBAR_LINKS = {
  // product: {
  //   title: 'menu.product.title',
  //   items: [
  //     {
  //       path: '/features',
  //       label: 'menu.product.items.features',
  //     },
  //     {
  //       path: '/templates',
  //       label: 'menu.product.items.templates',
  //     },
  //     {
  //       path: '/automation',
  //       label: 'menu.product.items.automation',
  //     },
  //   ],
  // },
  resources: {
    title: 'menu.resources.title',
    items: [
      {
        path: COMMUNITY_URL,
        label: 'menu.resources.items.community',
        type: 'image',
        src: solutionsImage.src,
      },
      {
        path: DOCUMENTATION_URL,
        label: 'menu.resources.items.help-center',
      },
      {
        path: 'https://docs.zealy.io/',
        label: 'menu.resources.items.api-docs',
      },
      {
        path: 'https://blog.zealy.io/',
        label: 'menu.resources.items.blog',
      },
    ],
  },
  company: {
    title: 'menu.company.title',
    items: [
      {
        path: '/about',
        label: 'menu.company.items.about',
      },
      {
        path: '/careers',
        label: 'menu.company.items.careers',
      },
      {
        path: 'https://zealy.notion.site/2f4b30cd3e8742ceb4fac35fa99ba9fa?v=8cba25cc2a864e05bfbc9106a2fdadc3',
        label: 'menu.company.items.media-kit',
      },
    ],
  },
} as const;

export type NavbarLinkMapper = typeof NAVBAR_LINKS;
