'use client';

import React from 'react';

import { useAuthenticatedUser } from '@zealy/queries';

import { ConnectedUserDropdown } from './ConnectedUserDropdown';

interface AuthNavProps {
  authChildren: React.ReactElement | null;
  children: React.ReactElement | null;
}

export const AuthNav = ({ authChildren, children }: AuthNavProps) => {
  const user = useAuthenticatedUser();

  // because isLoading = false when enabled on useAuthenticatedUser = false, we cannot do that check
  // it causes a problem between server side and client side display
  // supposed to be fixed in latest versions ? https://github.com/TanStack/query/issues/3975
  // also maybe the navar should just be a client component so nothing runs on the server ?
  // if (user.isLoading) {
  //   return null;
  // }
  return !user.data ? (
    children
  ) : (
    <>
      {authChildren}
      <ConnectedUserDropdown user={user.data} />
    </>
  );
};
