'use client';

import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import {
  Avatar,
  DropdownContent,
  DropdownItem,
  DropdownMenu,
  DropdownPortal,
  DropdownTrigger,
} from '@zealy/design-system';
import { Comment2QuestionLine, GearLine, LogOut2Line } from '@zealy/icons';
import { CommunityMember, User } from '@zealy/queries';

import { IUser } from '#types';
import { cn } from '#utils/utils';

import { useNavbarActions } from './Navbar.helpers';

export const ConnectedUserDropdown = ({ user }: { user: User | CommunityMember }) => {
  const t = useTranslations('navbar');
  const intercom = useIntercom();
  const { signOut, openSettings } = useNavbarActions();

  return (
    <DropdownMenu name="connected-user-dropdown" className="!gap-0">
      <DropdownTrigger className="rounded-full outline-none hidden md:block !px-0">
        {/* Doesn't update when updating because the name is the same */}
        <Avatar
          src={user?.avatar?.startsWith('/static') ? undefined : user?.avatar}
          name={user?.name}
          as={Image}
        />
      </DropdownTrigger>

      <DropdownPortal>
        <DropdownContent
          className={cn(
            'z-30 will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade',
          )}
          sideOffset={16}
          align="end"
        >
          <DropdownItem onClick={intercom.show} leftIcon={<Comment2QuestionLine size={16} />}>
            Contact support
          </DropdownItem>
          <DropdownItem onClick={openSettings} leftIcon={<GearLine size={16} />}>
            {t('account-settings')}
          </DropdownItem>
          <DropdownItem onClick={signOut} leftIcon={<LogOut2Line size={16} />}>
            {t('logout')}
          </DropdownItem>
        </DropdownContent>
      </DropdownPortal>
    </DropdownMenu>
  );
};
